import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"

const SecondPage = () => (
  <StaticQuery 
    query={graphql`
      query AboutPageQuery {
        contentfulPage(pageName: {eq: "About"}) {
          id
          pageContent {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                title
                gatsbyImageData
              }
            }
          }
        }
      }
    `}
    render = {data => {
      const Bold = ({ children }) => <span className="bold">{children}</span>
      const Text = ({ children }) => <p className="align-center">{children}</p>
      
      const options = {
        renderMark: {
          [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
          [BLOCKS.EMBEDDED_ASSET]: node => {
            const image = getImage(node.data.target)
            return (
              <>
                <GatsbyImage image={image} alt={node.data.target.title} style={{display: 'block', margin: '0 auto'}} />
              </>
            )
          },
        },
      }


      return (
        <>
          <Seo title="About" />
          {renderRichText(data.contentfulPage.pageContent, options)}
          <Link to="/">Go back to the homepage</Link>
        </>
      )
    }}
  />
)

export default SecondPage
